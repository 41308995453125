<template>
  <!-- table组件 -->
  <div>
    <el-table
        ref="multipleTable"
        :data="currentPageData"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      v-loading="loading"
        border
        style="width: 100%"
        :header-cell-style="{ background: 'rgb(245,250,254)', color: '#000' }"
        @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
          v-for="(item, index) in tableTop"
          align="center"
          header-align="center"
          :key="index"
          :label="item.fieldName"
          :prop="item.field"
      >
      </el-table-column>
      <!--             v-if="textinsType=='申报入口' || (roleName==='超级管理员' || roleName==='管理员')" -->
      <el-table-column

          header-align="center"
          align="center"
          label="操作"
          width="150"
          style="display: flex; justify-content: left"
      >
        <template slot-scope="scope">
          <!-- 运输管理：（管理员）   申报入口展示 台账管理隐藏 -->
          <div v-if="showSubmitOaFlag">
            <i
                v-if="textinsType==='申报入口' && roleName=='管理员'"
                class="el-icon-edit"
                title="修改"
                @click="handleModify(scope.$index, scope.row)"
            ></i>
          <!-- 运输管理：（超级管理员）   都展示 -->
            <i
                v-if="roleName==='超级管理员'"
                class="el-icon-edit"
                title="修改"
                @click="handleModify(scope.$index, scope.row)"
            ></i>
            <i

                class="el-icon-delete"
                title="删除"
                @click="clickNowRow(scope.$index, scope.row)"
            ></i>
          </div>
          <div v-if="!showSubmitOaFlag" class="operation-btns">
            <el-button
                class="operation-btn modify-btn"
                icon="el-icon-edit"
                title="修改"
                @click="handleModify(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
                class="operation-btn upload-btn"
                icon="el-icon-upload"
                title="上传附件"
                @click="handleUploadAttachment(scope.$index, scope.row)"
            >
              上传附件
            </el-button>
          </div>

          <!-- Upload Attachment and View Attachment buttons in another row -->
          <div v-if="!showSubmitOaFlag" class="operation-btns">
            <el-button
                class="operation-btn delete-btn"
                icon="el-icon-delete"
                title="删除"
                @click="clickNowRow(scope.$index, scope.row)"
            >
              删除
            </el-button>
            <el-button
                class="operation-btn view-btn"
                icon="el-icon-view"
                title="查看附件"
                @click="handleViewAttachment(scope.$index, scope.row)"
            >
              查看附件
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 5px">
      <el-button
          class="table-bottom-btns"
          style="background-color: rgb(231, 102, 109)"
          type="danger"
          @click="handleDelete()"
      >
        <i style="font-size: 15px" class="el-icon-delete"></i
        >批量删除
      </el-button
      >

      <el-button class="table-bottom-btns" @click="toggleSelection()">
        <i style="font-size: 15px" class="el-icon-s-release"></i
        >取消选择
      </el-button
      >
      <el-button
          v-if="showSubmitOaFlag && textinsType!=='台账管理'"
          class="table-bottom-btns"
          style="background-color: rgb(31, 02, 19)"
          type="info"
          @click="handleDeleteOA()"
      >
        <i style="font-size: 15px" class="el-icon-s-promotion"></i
        >提交OA
      </el-button
      >
    </div>

    <!-- 分页器 -->
    <div v-if="tableData.length" style="text-align: left; margin-top: 5px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
      >
      </el-pagination>
    </div>

    <!-- 上传附件的弹窗 -->
    <el-dialog
        title="上传附件"
        :visible.sync="uploadDialogVisible"
        width="50%"
        @close="handleCloseUploadDialog"
    >
      <div class="upload-category">
        <!-- 遍历分类并渲染上传组件 -->
        <div v-for="(category, index) in categories" :key="index" class="upload-row">
          <div class="category-title">{{ category.label }}</div>
          <el-upload
              ref="upload"
              :file-list="category.fileList"
              drag
              :action="url"
              :on-success="(response, file) => handleUploadSuccess(response, file, category)"
              :on-error="(error, file) => handleUploadError(error, file, category)"
              :on-change="file => changeUpload(file, category)"
              :show-file-list="true"
              :limit="category.limit"
              :auto-upload="false"
              :data="uploadData"
              multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">拖拽文件到这里，或者<em>点击上传</em></div>
            <div class="el-upload__tip" style="text-align: center" slot="tip">
              只能上传 JPG、PNG、PDF 文件，最多上传 {{ category.limit }} 个
            </div>
          </el-upload>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseUploadDialog">取消</el-button>
        <el-button type="primary" @click="handleSubmitUpload">上传</el-button>
      </div>
    </el-dialog>

    <!-- 查看附件的弹窗 -->
    <el-dialog
        title="查看附件"
        :visible.sync="viewDialogVisible"
        width="80%"
        @close="handleCloseViewDialog"
    >
      <div class="view-category" >
        <!-- 遍历分类并渲染附件 -->
        <div v-for="(category, index) in uploadResultFileList" :key="index" class="view-category-row">
          <div class="category-title">{{ category.category + ":" }}</div>
          <div class="file-container" style="max-height: none; overflow: unset;">
            <!-- 遍历该分类下的文件 -->
            <div
                v-for="(file, fileIndex) in category.fileList"
                :key="fileIndex"
                class="file-item"
            >
              <div style="margin-top: 10px" v-if="isImage(file)">
                <el-image
                    style="width: 150px; height: 150px"
                    :src="file.filePath"
                    :preview-src-list="previewList"></el-image>
              </div>
              <div style="margin-top: 10px" v-else-if="isPdf(file)">
                <el-image
                    style="width: 150px; height: 150px"
                    src="https://hywlpt.oss-cn-shanghai.aliyuncs.com/pdf_img.png"
                    :preview-src-list="previewList"></el-image>
              </div>
              <div class="file-name">
                <p>{{ file.name }}</p>
                <el-button
                    v-if="isPdf(file)"
                    @click.stop="downloadFile(file)"
                    size="small"
                    type="primary"
                >
                  下载
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseViewDialog">关闭</el-button>
        <el-button type="primary" @click="downloadAllFiles">全部下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import {deleteTableData,deleteLpTableData} from "@/axios/Home/index.js"; //删除
import {submitYsOA, submitLpOA} from "@/axios/tableTop"; //提交运输管理OA //提交理赔管理oa
import {queryFiles} from "@/axios/file.js"
import JSZip from 'jszip';
import {saveAs} from 'file-saver';

export default {
  props: {
    // 表头数据
    tableTop: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    templateExporst: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 总条数
    declCount: {
      type: Number,
      default: 0,
    },
    // 当前险种类型
    insType: {
      type: String,
      default: "",
    },
    // 管理类型
    pageType: {
      type: String,
      default: "",
    },
    showSubmitOaFlag: {
      type: Boolean,
      default: true
    },
  },
  data() {
     const textinsType=JSON.parse(localStorage.getItem("insType"));
    return {
      allTableData: [], // 保存所有数据
    currentPageData: [], // 当前页显示的数据
    multipleSelection: [], // 当前选中的数据
      textinsType,
      ListName:JSON.parse(localStorage.getItem("ListName")),
      url: window.ipConfigUrl.baseURL1 + 'upload/img',
      fileList: [], // 用于 理赔存储上传的文件列表
      //当前用户管理员id
      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 个数选择器（可修改）
      pageSizes: [50, 100],
      // 默认每页显示的条数（可修改）
      PageSize: 50,
      isLp: false, //true是理赔 false不是理赔
      isModify: false, //true是编辑 false不是编辑
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
      uploadData: {
        policyNumber: ''
      },
      multipleSelection: [], // 用于存储选中项
      records: [],
      roleName: JSON.parse(localStorage.getItem("roleName")),
      textinsType: JSON.parse(localStorage.getItem("insType")),
      username: JSON.parse(localStorage.getItem("username")),
      uploadDialogVisible: false, // 控制上传弹窗显示
      viewDialogVisible: false,
      // 当前查看的文件索引
      currentIndex: 0,
      // 文件列表，包含多个文件
      // 上传分类
      categories: [
        {label: "现场图片:", fileList: [], limit: 50},
        {label: "落地图片:", fileList: [], limit: 50},
        {label: "证件图片:", fileList: [], limit: 50},
        {label: "索赔单证:", fileList: [], limit: 50},
        {label: "其他:", fileList: [], limit: 50},
      ],
      uploadResultFileList: [],
      previewList: [], //预览数组
      isLoading: false, // 是否在加载更多文件
      currentPolicyNumber: '', //当前保单号
      loading: true,
      ywlxmc:JSON.parse(localStorage.getItem("ywlxmc")), // 获取业务类型
      allowedFileTypes: ["image/png", "image/jpeg", "application/pdf"], // 文件类型限制
      // 动态判断申报||台账
      operationType:textinsType=='申报入口' ? "filingPortal" : "ledgerManagement"
    };
  },
  methods: {
    tableRowClassName({row}) {
      // 行样式逻辑
      return row.status === "导入失败"
          ? "orange-row"
          : row.status === "审批通过"
              ? "green-row"
              : row.status === "删除"
              ? "grown-row"
              : row.status === "审批不通过"
              ? "red-row"
              : "";
    },
    tableCellClassName({row, column}) {
      
      // 测试
      // 如果 errorColumn 与当前列的 field 相等，则标记该单元格为 error-cell
      if (row.errorColumn) {
        if (row.errorColumn === column.property) {
        return "error-cell";
      }
      }
      return "";
    },
    updateCurrentPageData() {
    const start = (this.currentPage - 1) * this.PageSize;
    const end = start + this.PageSize;
    this.currentPageData = this.allTableData.slice(start, end);
      console.log(this.allTableData.slice(start, end));
      
    
  },
    handleSizeChange(val) {
      this.PageSize = val;
      // this.$emit("sizeChange", val);
      this.updateCurrentPageData()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.$emit("currentChange", val);
      this.updateCurrentPageData()
    },

    // 表格取消 复选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 暴露table数据 用来捞出excel
    getTableData() {
      // 返回表格数据，这里简单返回整个数组
      return this.tableData;
    },
    //  暴露tabletop数据 用来捞出excel
    getTableTop() {
      // 返回表格数据，这里简单返回整个数组
      return [
        console.log(this.tableTop,11111),
        this.tableTop.map((item) => item.fieldName),
        this.tableTop.map((item) => item.field),
 
        
      ];
    },
    // 理赔
    handleEdit() {
      this.isLp = true;
      this.$emit("isLp", this.isLp);
    },
    // 编辑
    handleModify(index, row) {
      this.isModify = true;
      this.$emit("isModify", this.isModify, row.id);
      this.$emit("rowData", row);
    },
    // 选中table 复选框数据
    handleSelectionChange(val) {
 console.log(this.allTableData);
 
  this.multipleSelection = val;
  this.$emit('selection-change', this.multipleSelection);
    },
    // 删除table 当前行
    clickNowRow(index, row) {
 
      if(this.textinsType=='申报入口' && row.status === "待审核"){
        this.$message({
          type: "warning",
          message: "该条数据正在审核!无法删除准备动态参数添加信息",
        });
        return
      }
      if (row.status === "删除" && (this.roleName!=='超级管理员')) {
        this.$message({
          type: "warning",
          message: "该条数据已删除!无权操作",
        });
        return
      }else{
        this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
 
            this.records = []; //防止不断push 导致数据重复
            let obj = row.id;
            this.records.push(obj);
            if (this.ywlxmc=='运输管理') {
 
              deleteTableData({records: this.records, username: this.username,operationType:this.operationType}).then((res) => {
              if (res.code == 200) {

                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.$emit("querytable", true);
                this.records = [];
              }
            });
            }else{
 
            deleteLpTableData({records: this.records, username: this.username,operationType :this.operationType}).then((res) => {
              if (res.code == 200) {

                this.$message({
                  type: "success",
                  message: "已提交删除申请!",
                });
                this.$emit("querytable", true);
                this.records = [];
              }
            });
            }



            // 操作删除唯一标识id
          })
          .catch(() => {
          });
      }

    },
    // 删除 table选择删除
    handleDelete() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择要删除的数据或取消勾选删除状态数据!",
        });
        return;
      } 
      
      // if (row.status === "删除" && (this.roleName!=='超级管理员'))
      else {
        this.$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          const message = `当前删除数据 ${this.multipleSelection.length} 条, 是否继续?`;
          const tag=`确定删除数据 ${this.multipleSelection.length} 条`;

          this.$confirm(message, '提示', {
          confirmButtonText: tag,
          cancelButtonText: '删除全部',
          type: 'warning'
        })
        .then(()=>{
          this.records = this.multipleSelection.map((item) => item.id);
          if (this.ywlxmc=='运输管理') {
 
            deleteTableData({records: this.records, username: this.username,operationType:this.operationType}).then((res) => {
            if (res.code == 200) {
            if (this.textinsType=='申报入口') {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }else{
              this.$message({
                type: "success",
                message: "已提交删除申请!",
              });
            }
              this.$emit("querytable", true);
              this.records = [];
            }
          });
          }else{
          

          deleteLpTableData({records: this.records, username: this.username,operationType :this.operationType}).then((res) => {
              if (res.code == 200) {
                if (this.textinsType=='申报入口') {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }else{
              this.$message({
                type: "success",
                message: "已提交删除申请!",
              });
            }
                this.$emit("querytable", true);
                this.records = [];
              }
            });
          // 操作删除唯一标识id
          }
        })
        .catch(() => {
          this.records =this.allTableData.map((item) => item.id);
          if (this.ywlxmc=='运输管理') {
 
            deleteTableData({records: this.records, username: this.username,operationType:this.operationType}).then((res) => {
            if (res.code == 200) {
            if (this.textinsType=='申报入口') {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }else{
              this.$message({
                type: "success",
                message: "已提交删除申请!",
              });
            }
              this.$emit("querytable", true);
              this.records = [];
            }
          });
          }else{
          deleteLpTableData({records: this.records, username: this.username,operationType :this.operationType}).then((res) => {
              if (res.code == 200) {
                if (this.textinsType=='申报入口') {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }else{
              this.$message({
                type: "success",
                message: "已提交删除申请!",
              });
            }
                this.$emit("querytable", true);
                this.records = [];
              }
            });
          // 操作删除唯一标识id
          }
        })
        })
        .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
      }

    },
    // 提交OA
    handleDeleteOA() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择要提交的数据!",
        });
        return;
      } else {
        this.$confirm("此操作将提交OA, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          const message = `当前提交OA数据 ${this.multipleSelection.length} 条, 是否继续?`;
        const tag=`确定提交OA数据 ${this.multipleSelection.length} 条`;
     
      this.$confirm(message, '提示', {
          confirmButtonText: tag,
          cancelButtonText: '提交全部',
          type: 'warning'
        }).then(() => {
          this.records = this.multipleSelection.map((item) => item.id);
          if (this.pageType == '0') {
            submitYsOA({username: this.username, insType: this.insType, records: this.records}).then((res) => {
             if (res.code==200) {
              this.$message({
                type: 'success',
                message: '提交成功',
              });
              this.$emit("querytable", true);
              this.records = [];
             }
            })
          } else {

            submitLpOA({username: this.username, insType: this.insType, records: this.records}).then((res) => {
              this.$message({
                type: 'success',
                message: '提交成功',
              });
              this.$emit("querytable", true);
              this.records = [];
            })
          }
})
          .catch(() => {
  if (this.pageType == '0') {
    this.records = this.allTableData.map((item) => item.id);
            submitYsOA({username: this.username, insType: this.insType, records: this.records}).then((res) => {
             if (res.code==200) {
              this.$message({
                type: 'success',
                message: '提交成功',
              });
              this.$emit("querytable", true);
              this.records = [];
             }
            })
          } else {

            submitLpOA({username: this.username, insType: this.insType, records: this.records}).then((res) => {
              this.$message({
                type: 'success',
                message: '提交成功',
              });
              this.$emit("querytable", true);
              this.records = [];
            })
          }
})
          

        })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消提交",
              });
            });
      }

    },
    //查看附件
    handleViewAttachment(index, row) {
      this.currentPolicyNumber = row.policyNumber;
      const params = {
        policyNumber: row.policyNumber
      }
      queryFiles(params).then((res) => {
        if (res.code == 200) {
          this.viewDialogVisible = true;
          this.uploadResultFileList = res.data;
          this.uploadResultFileList.forEach((result) => result.fileList.forEach(file => this.isImage(file) ? this.previewList.push(file.filePath) : this.previewList.push("https://hywlpt.oss-cn-shanghai.aliyuncs.com/pdf_img.png")))
        }
      })
    },
    downloadAllFiles() {
      const zip = new JSZip();
      const allFiles = []
      this.uploadResultFileList.forEach((result) => allFiles.push(...result.fileList));
 
      const filePromises = allFiles.map((file) => {
        return fetch(file.filePath + "?s=" + Math.random().toString())
            .then((response) => response.blob())
            .then((blob) => {
              zip.file(file.name, blob);
            });
      });

      // 等待所有文件都下载完
      Promise.all(filePromises).then(() => {
        zip.generateAsync({type: 'blob'}).then((content) => {
          // 使用 file-saver 库保存文件
          saveAs(content, this.currentPolicyNumber + '.zip');
        });
      });
    },
    // 判断文件是否是图片
    isImage(file) {
 
      const imageTypes = ['jpg', 'jpeg', 'png', 'gif'];
      const fileType = file.name.split('.').pop().toLowerCase();
      return imageTypes.includes(fileType);
    },
    // 判断文件是否是 PDF
    isPdf(file) {
      return file.name.toLowerCase().endsWith('.pdf');
    },

    // 下载文件
    downloadFile(file) {
      const link = document.createElement('a');
      link.href = file.filePath;
      link.download = file.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 滚动事件，加载更多文件
    handleScroll(event) {
      const container = event.target;
      const isBottom = container.scrollHeight === container.scrollTop + container.clientHeight;

      if (isBottom && !this.isLoading) {
        this.isLoading = true;
        this.loadMoreFiles();
      }
    },
    // 加载更多文件（模拟异步请求）
    loadMoreFiles() {
      setTimeout(() => {
        const newFiles = [
          {
            name: `File ${this.uploadResultFileList.length + 1}.jpg`,
            url: `https://example.com/path/to/file${this.uploadResultFileList.length + 1}.jpg`
          },
          {
            name: `File ${this.uploadResultFileList.length + 2}.pdf`,
            url: `https://example.com/path/to/file${this.uploadResultFileList.length + 2}.pdf`
          },
        ];
        this.uploadResultFileList.push(...newFiles);
        this.isLoading = false;
      }, 1000);
    },
    // 关闭查看附件的弹窗
    handleCloseViewDialog() {
      this.viewDialogVisible = false;
      this.fileList = [];
      this.uploadResultFileList = []
      this.previewList = [];
      this.currentPolicyNumber = '';
    },

    // 打开上传附件弹窗
    handleUploadAttachment(index, row) {
      this.uploadDialogVisible = true;
      this.uploadData.policyNumber = row.policyNumber;
    },

    // 提交上传的文件
    handleSubmitUpload() {
      this.categories.forEach((category) => {
        const formData = new FormData();
        if (category.fileList.length === 0) return;
        // 进行分拨上传
        formData.append("policyNumber", this.uploadData.policyNumber);
        // 拼接当前分类
        formData.append("category", category.label);
        //拼接当前分类下的文件
        category.fileList.forEach(file => {
          formData.append("files", file.raw); // 假设 file.raw 是文件对象
        });
        axios.post(this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
            .then((response) => {
              if (response.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "文件上传成功！",
                });

                // 清空各分类的文件列表
                this.categories.forEach((category) => {
                  category.fileList = [];
                });

                this.uploadDialogVisible = false; // 关闭上传弹窗
              } else {
                this.$message({
                  type: "error",
                  message: "文件上传失败！",
                });
              }
            })
            .catch(() => {
              this.$message.error("文件上传失败！");
            });
      });
    },
    // 处理文件变更（文件选择时校验）
    changeUpload(file, category) {
      category.fileList.push(file);

      const isFileTypeAllowed = this.allowedFileTypes.includes(file.raw.type); // 文件类型校验
      const isFileSizeAllowed = file.raw.size / 1024 / 1024 <= 1024; // 文件大小校验

      if (!isFileTypeAllowed) {
        this.$message.error(`文件 "${file.name}" 类型错误，只能上传 JPG、PNG 或 PDF 文件！`);
        category.fileList.pop(); // 删除不符合条件的文件
      } else if (!isFileSizeAllowed) {
        this.$message.error(`文件 "${file.name}" 超过大小限制（最大 1024 MB）！`);
        category.fileList.pop(); // 删除不符合条件的文件
      }
    },

    // 上传成功的处理
    handleUploadSuccess(response, file, category) {
      this.$message.success(`分类 "${category.label}" 的文件 "${file.name}" 上传成功！`);
    },

    // 上传失败的处理
    handleUploadError(error, file, category) {
      this.$message.error(`分类 "${category.label}" 的文件 "${file.name}" 上传失败！`);
    },

    // 关闭上传附件弹窗
    handleCloseUploadDialog() {
      this.uploadDialogVisible = false;
    },
  },
  mounted() {
    if (this.tableData.length > 0) {
  
      this.allTableData = this.tableData; // 初始化时保存所有数据
      this.updateCurrentPageData(); // 初始化时更新当前页数据
      // 关闭加载
      this.loading = false;
    }
  },
  watch: {
    // 监听 tableData 的变化
    tableData: {
      immediate: true, // 立刻执行一次
      handler(newVal) {
        this.allTableData = newVal; // 保存所有数据
        this.updateCurrentPageData(); // 更新当前页数据
        // 当 tableData 变化时检查其长度
        if (newVal.length > 0) {
          this.loading = false; // 如果有数据，则关闭加载
        } else {
          this.loading = false; // 如果没有数据，则保持加载状态
        }
      },
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .error-cell {
  background-color: #ff0000; /* 红色背景 */
  color: white; /* 字体颜色 */
}

/deep/ .el-table .orange-row {
  background-color: orange !important;
  color: white;
}

/deep/ .el-table .orange-row:hover {
  color: #0a0a0a !important;
}

/deep/ .el-table .green-row {
  background-color: #95ec69 !important;
}
/deep/ .el-table .grown-row{
  background-color: #9fa19e !important;
}
/deep/ .el-table .red-row
{
  background-color: #f36ab5 !important;
}
.table-bottom-btns {
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  font-size: 12px;
  color: white;
  font-weight: 900;
  background-color: rgba(0, 150, 136);
}

.table-bottom-btns:hover {
  opacity: 0.8;
  /* 举例使用 Element UI 的主题色作为文字颜色 */
}

// 强制清空table表格行间隙
/deep/ .el-table .el-table__cell {
  padding: 4px 0;
}

i {
  margin-right: 6px;
  cursor: pointer;
}

/deep/ .el-table .cell {
  font-size: 12px !important;
  padding: 0px;
}

.operation-btns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
}

/* Set equal width for Modify and Upload buttons */
.modify-btn,
.delete-btn {
  margin-left: 3px;
  width: 40%;
  padding-left: 5px;
  font-size: 10px;
}

/* Set equal width for Delete and View buttons */
.upload-btn,
.view-btn {
  width: 47%; /* Match widths of Delete and View buttons */
  font-size: 10px;
  padding-left: 5px;
  margin-right: 10px;
}

/* Optional: Styling for buttons icons */
.operation-btn i {
  font-size: 16px;
  cursor: pointer;
  color: #409EFF;
}

.operation-btn i:hover {
  color: #66b1ff;
}

.upload-demo {
  display: flex;
  justify-content: center;
}

.view-attachment {
  text-align: center;
}

.file-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.file-item {
  width: 150px;
}

.file-name {
  margin-top: 10px;
}

.file-image {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}

.el-dialog__footer {
  display: flex;
  justify-content: center;
}

.upload-category {
  display: flex;
  flex-direction: column;
}

.upload-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.category-title {
  width: 150px;
  font-weight: bold;
}
</style>
